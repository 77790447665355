const useEventsCollector = () => {
	const startDate = new Date(Date.UTC(2024, 10, 18, 5, 0, 0));
	const endDate = new Date(Date.UTC(2024, 10, 30, 5, 0, 0));

	const { durationExceeded: exceededStart } = useCountdown({ timestamp: startDate.toString(), isOptimized: true });
	const { durationExceeded: exceededEnd } = useCountdown({ timestamp: endDate.toString(), isOptimized: true });

	const isEventTheme = computed(() => exceededStart.value && !exceededEnd.value);

	return {
		isEventTheme
	};
};

export default useEventsCollector;
